/* eslint-disable @nx/enforce-module-boundaries */
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Text,
  Image,
  HStack,
  Button,
  Flex,
  Card,
  CardBody,
  CardFooter,
  VStack,
} from '@mybridge/ui';
import Link from 'next/link';
import { EventDefaultImage } from '@mybridge/icons/EventDefaultImage';
import {
  EventClockIcon,
  EventHostIcon,
  LocationIcon,
  LinkIcon,
  CalendarIcon,
  AlarmIcon,
  AuthorIcon,
  AuthorIconEvent
} from '@mybridge/icons';
import { PostBoxContext, usePostBoxContext } from '../context';
import moment from 'moment';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSelectedEvent } from 'v4/store/actions/calendar.actions';
import { useRouter } from 'next/router';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const EventPostBody = ({fromPublicEvent=false}) => {
  const { post, userProfileInfo, acceptEventLoading, acceptEvent } =
    useContext(PostBoxContext);
    const router=useRouter()
    const { loggedIn } = useSelector((state) => state.user);
const dispatch=useDispatch()
  const {
    id,
    title,
    author,
    media,
    start_time,
    end_time,
    attendees,
    event_type,
  } = post;

  const attending = attendees?.find?.(
    (a) => a?.user?.id === userProfileInfo?.id
  );

  const handleselectEvents=(event)=>{
    !loggedIn&&dispatch(setIsSelectedEvent(event))
    router.push(`/g/events/${event?.id}`)
  }
  const { t } = useTranslator();
  const currentLanguage = useSelector((state) => state.translation.language);

  return (
    <>
      <Card mb={3} border="1px solid #E4E6EB" borderRadius="20px" ml={!fromPublicEvent&&!isMobile?"60px":"0px"} width={isMobile?"100%":"70%"}>
        <CardBody p={0}>
          {media[0]?.file && (
            <Image
              src={media[0].file}
              alt={title}
              maxW="100%" // Ensure the image takes full width of the Card
              borderTopRadius="20px" // Match borderRadius of Card
              objectFit="cover"
              h="225px"
              w="100%"
            />
          )}
          {/* Content Section */}
          <VStack align="flex-start" spacing={2} flex={1} p={4}>
            {' '}
            {/* Add padding for content */}
            <Text
              as={loggedIn?Link:Text}
              href={`/myevents/details/${id}`}
              fontWeight="medium"
              color="#3D5A80"
              lineHeight="1.2"
              fontSize="20px"
              cursor="pointer"
              isTruncated
              whiteSpace={"normal"}
              onClick={()=>{handleselectEvents(post)}}
            >
              {title?.length > 83
                ? `${title.slice(0, 83)}...`
                : title || (t('createPost.event') || 'Event')}
            </Text>
            <HStack>
              <CalendarIcon color="#9F9F9F" width="19px" height="17px" />
              <Text fontSize="14px">
                {moment(start_time).local().format('MMM DD, YYYY')}
              </Text>
            </HStack>
            <HStack>
              <AlarmIcon color="#9F9F9F" width="19px" height="17px" />
              <Text fontSize="14px">
                {moment(start_time).local().format('hh:mm A')} -{' '}
                {moment(end_time).local().format('hh:mm A')}
              </Text>
            </HStack>
            {post?.author?.location && (
              <HStack>
                <LocationIcon color={"#9F9F9F"} width="19px" height="17px" />
                <Text fontSize="14px">{post?.author?.location}</Text>
              </HStack>
            )}
            {(attending?.status === 'ACCEPT' ||
              userProfileInfo?.id === author?.id) && (
              <HStack>
                <LinkIcon color="#9F9F9F" width="19px" height="17px" />
                <Text fontSize="14px" isTruncated>
                  <Link
                  style={{whiteSpace:"normal"}}
                    href={`/myevents/details/${id}`}
                  >{`${process.env.BASE_PUBLIC_URL}myevents/details/${id}`}</Link>
                </Text>
              </HStack>
            )}
            <HStack>
              <AuthorIconEvent width="19px" height="17px" />
              <Text fontSize="14px">
                <Link href={`/p/${author?.slug}`}>
                  {getUserFullName(author)}
                </Link>
              </Text>
            </HStack>
          </VStack>
        </CardBody>

        <CardFooter p={4}>
          <Flex justify="space-between" align="center">
            {/* RSVP/Register Button */}
            {attending?.status === 'ACCEPT' ? (
              <Button
                as={Link}
                href={`/myevents/details/${id}`}
                size="sm"
                height="26px"
                fontSize="12px"
                color="#5B5B5B"
              >
                {t('pageJobs.viewDetails') || "View Details"}
              </Button>
            ) : (
              <Button
                isLoading={acceptEventLoading}
                onClick={() => acceptEvent(post)}
                size="sm"
                border="1px solid #00000033"
                borderRadius="27px"
                fontSize="12px"
                color="#5B5B5B"
                bg="none"
              >
                {event_type === 'general' ? 'RSVP' : t('eventDashboard.register')}
              </Button>
            )}
          </Flex>
        </CardFooter>
      </Card>
    </>
  );
};
